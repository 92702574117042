import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import VCalendar from 'v-calendar';

Vue.use(Buefy)
Vue.use(VCalendar)
Vue.config.productionTip = false;


new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

