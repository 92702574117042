import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    props: true,
    name: "HomePage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HomePage.vue"),
      meta: { title: 'Home' } 
  },
  {
    path: "/providerlanding",
    props: true,
    name: "ProviderSearchLanding",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProviderSearchLanding.vue"),
      meta: { title: 'Provider Search Landing' } 
  },
  {
    path: "/providersearch",
    props: true,
    name: "ProviderSearch",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProviderSearch.vue"),
      meta: { title: 'Provider Search' } 
  },
  {
    path: "/contact",
    name: "ContactPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactPage.vue"),
      meta: { title: 'Contact' } 
  },
  {
    path: "/resources",
    name: "ProviderResources",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProviderResources.vue"),
      meta: { title: ' Provider Resources' } 
  },
  {
    path: "/test",
    name: "TestVueComponent",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TestVueComponent.vue"),
      meta: { title: 'Test' } 
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title

  next()
});

export default router;
